import { Button, Flex } from '@pancakeswap/uikit'
import Link from 'next/link'
import styled from 'styled-components'

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  background: black;
  padding: 120px 16px 88px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 72px 0 88px;
  }
`

const StyledText = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 26px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 40px;
    padding-bottom: 54px;
  }
`

const TokenomicsImageContainer = styled.div`
  background: #221b20;
  max-width: 329px;
  border-radius: 6px;
  width: 100%;
  padding: 8px 12px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-radius: 20px;
    max-width: 1036px;
    padding: 36px 46px;
    width: 90%;
  }
`

const MoreInfoButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  margin-top: 36px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 48px;
    font-size: 20px;
  }
`

const Tokenomics = () => {
  return (
    <Container>
      <StyledText>Tokenomics & Permissionless Pools Mechanics</StyledText>
      <TokenomicsImageContainer>
        <img src="/images/home/tokenomics.png" alt="man-tokenomics" />
      </TokenomicsImageContainer>
      {/* TODO: Wait for the document link */}
      <Link
        target="_blank"
        href="https://docs.mancakeswap.finance/mancakeswap/governance-and-tokenomics/man-tokenomics"
      >
        <MoreInfoButton>More info</MoreInfoButton>
      </Link>
    </Container>
  )
}

export default Tokenomics
