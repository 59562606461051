import { Box, PageSection } from '@pancakeswap/uikit'
import Image from 'next/image'
import { styled } from 'styled-components'
import { AffiliateSection } from './components/AffiliateSection'
import { DiscoverEcosystem } from './components/DiscoverEcosystem'
import Hero from './components/Hero'
import Tokenomics from './components/Tokenomics'

const StyledHeroSection = styled(PageSection)`
  overflow: hidden;
  padding-top: 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 80px;
  }
`

const HeroBunnyImage = styled(Image)`
  position: absolute;
  right: 0;
  height: auto;
  bottom: 35px;
  z-index: -1;
  width: 500px;

  ${({ theme }) => theme.mediaQueries.sm} {
    bottom: 0px;

    @media (min-height: 832px) {
      bottom: 32px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 856px;
  }
`

const HeroWaveImage = styled.img`
  position: absolute;
  max-width: 100dvw;
  min-width: fit-content;
  height: 150px;
  bottom: -36px;
  left: -300px;

  ${({ theme }) => theme.mediaQueries.sm} {
    bottom: -60px;
    left: -85px;
    height: 200px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    bottom: -50px;

    @media (min-height: 832px) {
      height: 274px;
    }
  }
`

const HeroCakeImage = styled.img`
  position: absolute;
  height: 300px;
  top: 280px;
  left: 0;
  display: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  return (
    <Box style={{ overflow: 'hidden', boxSizing: 'border-box' }}>
      <style jsx global>
        {`
          #home-1 .page-bg {
            background: linear-gradient(to bottom, #14080d 30%, #4a0821 80%, #12070b 100%);
          }
        `}
      </style>
      <StyledHeroSection
        style={{ position: 'relative', height: 'calc(100dvh - 56px)', paddingBottom: 0 }}
        innerProps={{ style: { margin: '0', width: '100%', overflow: 'visible', padding: '0' } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <Hero />
        <HeroCakeImage src="/images/home/hero-cake.svg" alt="hero-cake" />
        <HeroBunnyImage width={1930} height={1720} src="/images/home/hero-bunny.png" alt="hero-bunny" />
        <HeroWaveImage src="/images/home/hero-wave2.svg" alt="hero-wave" />
      </StyledHeroSection>
      <DiscoverEcosystem />
      <Tokenomics />
      <AffiliateSection />
    </Box>
  )
}

export default Home
